/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/global.css"
import {getCookieConsentValue} from "react-cookie-consent";

// on page load, disable hubspot tracking if user has opted out
export const onClientEntry = () => {
  var _hsq = window._hsq = window._hsq || [];
  _hsq.push(['doNotTrack']);
  if (getCookieConsentValue() === "true") {
    _hsq.push(['doNotTrack', {track: true}]);
  }
}